.landing-grid {
  background: #f0f2f0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #000c40,
    #f0f2f0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #000c40,
    #f0f2f0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.header-color {
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #606c88,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #606c88,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.avatar {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 250px;
  height: 250px;
  border-radius: 100%;

  position: relative;
  box-shadow: 1px 2px 90px rgba(0, 0, 0, 0.5);
  padding: 10px;
  background: white;
}
.banner-text {
  background-color: grey;
  opacity: 0.7;
  margin: auto;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: auto;
  box-shadow: 8px 8px;
}
.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 4px 4px black;
}
.banner-text hr {
  border-bottom: 5px groove white;
  width: 80%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
  text-align: center;
}
.social-links {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.projects-grid {
  display: flex;
}
.contact-body {
  margin: auto;
  background: grey;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  border: 2px solid navy;
  margin-bottom: 20px;
}
.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}
.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}
.contact-grid hr {
  border-top: 5px groove white;
  width: 50%;
  margin: auto;
}
.contact-list i {
  font-size: 60px;
  padding-right: 2rem;
}
.contact-photo {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 250px;
  height: 250px;
  border-radius: 100%;
}
.resume-body {
  background: #000034;
  color: white;
}
.resumephoto {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 250px;
  height: 250px;
  border-radius: 100%;
}
h1 {
  text-align: center;
  color: white;
}
button {
  text-align: center;
  color: white;
}
.emailpopup {
  font-size: 0.875em;
  display: block;
  margin-top: 35px;
  width: 100%;
  font-family: 'Righteous', cursive;
}
.linkedin {
  font-size: 0.875em;
  display: block;
  margin-top: auto;
  width: 100%;
  font-family: 'Righteous', cursive;
}
.github {
  font-size: 0.875em;
  display: block;
  margin-top: 35px;
  width: 100%;
  font-family: 'Righteous', cursive;
}
h1 {
  text-shadow: 2px 6px gray;
}
.header-line {
  border-top: 5px groove white;
  width: 100%;
  margin: auto;
}
.code-details {
  text-align: center;
  font-size: 15;
  color: #f0f2f0;
}
